<template>
  <div id="sonic.water.output.numerical.index">
    <b-row>
      <b-col md='12' class="text-center">
        <h1 class="display-4">{{$t('sonic.project.numerical.title')}}</h1>
        <hr>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="4">
        <input-table />
        <determined-table />
        <calculated-table />
      </b-col>
      <b-col lg="8">
        <flow-table />
        <save />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import inputTable from './inputTable'
import determinedTable from './determinedTable'
import calculatedTable from './calculatedTable'
import flowTable from './flowTable'
import save from './save'
export default {
  name: 'sonic.water.output.numerical.index',
  components: {
    inputTable,
    determinedTable,
    calculatedTable,
    flowTable,
    save
  }
}
</script>

<style>

</style>