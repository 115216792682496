<template>
  <div id="sonic.water.output.numerical.determined.table">
    <b-row>
      <b-col md="12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th
                scope="col"
                colspan="3"
                class="text-center"
              >{{$t('sonic.project.numerical.determined.title')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-right">{{$t('sonic.project.numerical.determined.table.mdot.title')}}</td>
              <td class="text-right">
                
                <b>{{results.inlet.mdot[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.mdot.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td
                class="text-right"
              >{{$t('sonic.project.numerical.determined.table.density.title')}}</td>
              <td class="text-right">
                <b>{{results.inlet.rho[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.density.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td
                class="text-right"
              >{{$t('sonic.project.numerical.determined.table.velocity.title')}}</td>
              <td class="text-right">
                <b>{{results.inlet.vel[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.velocity.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td class="text-right">{{$t('sonic.project.numerical.determined.table.mach.title')}}</td>
              <td class="text-right">
                <b>{{results.inlet.mach[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.mach.short',0)}}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "sonic.water.output.numerical.determined.table",
  computed: {
    ...mapGetters({
      results: 'getSonicResults'
    })
  }
};
</script>

<style>
</style>