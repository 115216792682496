<template>
    <b-button 
    variant="success"
    @click="clicked()"
    ><i class="fas fa-download"></i> {{text}}</b-button>
</template>

<script>
export default {
    name: 'components.general.storeButton',
    props: ['text'],
    methods: {
        clicked() {
            this.$emit('clicked')
        }
    }
}
</script>

<style>

</style>