<template>
  <div id="sonic.water.output.numerical.input.table">
    <b-row>
      <b-col md="12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th
                scope="col"
                colspan="3"
                class="text-center"
              >{{$t('sonic.project.numerical.input.title')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-right">{{$t('sonic.project.numerical.input.table.Pi.title')}}</td>
              <td class="text-right">
                <b>{{properties.Pi[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.pressure.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td class="text-right">{{$t('sonic.project.numerical.input.table.Ti.title')}}</td>
              <td class="text-right">
                <b>{{properties.Ti[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.temperature.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td class="text-right">{{$t('sonic.project.numerical.input.table.Pe.title')}}</td>
              <td class="text-right">
                <b>{{properties.Pe[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.pressure.short',0)}}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "sonic.water.output.numerical.input.table",
  computed: {
    ...mapGetters({
      properties: 'getSonicResultsProperties'
    })
  }
};
</script>

<style>
</style>