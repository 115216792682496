<template>
  <div id="sonic.water.output.visual.charts.vaporQuality">
    <line-chart :id="chartId" :chart-data="chartData" :options="chartData.options" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LineChart from "@/scripts/vendor/LineChart";
import logic from "@/scripts";
export default {
  name: "sonic.water.output.visual.charts.vaporQuality",
  components: { LineChart },
  computed: {
    ...mapGetters({
      results: "getSonicResults",
    }),
    chartId() {
      return logic.output.visual.vaporQuality.id;
    },
    chartData() {
      return {
        labels: logic.output.visual.parseParts(this.results.serial.graph),
        datasets: [
          {
            label:
              this.$t("units.vaporQuality.title") +
              " [" +
              this.$tc("units.vaporQuality.short", 0) +
              "]",
            chartId: logic.output.visual.pressure.id,
            backgroundColor: logic.output.visual.vaporQuality.backgroundColor,
            borderColor: logic.output.visual.vaporQuality.borderColor,
            lineTension: logic.output.visual.vaporQuality.lineTension,
            fill: logic.output.visual.vaporQuality.lineFill,
            data: this.results.x.graph.data
          },
        ],
        options: {
          responsive: logic.output.visual.vaporQuality.responsive,
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                  max: 1,
                  stepSize: 0.1,
                },
              },
            ],
          },
        },
      };
    },
  },
};
</script>

<style>
</style>