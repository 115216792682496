<template>
  <div id="sonic.water.output.visual.save">
    <b-row>
      <b-col sm="12" class="d-flex justify-content-center">
        <download-button
        :text="$t('sonic.project.visual.buttons.save')"
        v-on:clicked="printChart()"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import jsPDF from 'jspdf'
import { mapGetters } from 'vuex'
import downloadButton from "@/components/general/downloadButton.vue";
export default {
  name: 'sonic.water.output.visual.save',
  components: {
    downloadButton
  },
  computed: {
    ...mapGetters({
      project: 'getSonicProject',
    })
  },
  methods: {
    printChart() {
      const canvasList = document.body.getElementsByTagName('canvas')
      var pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4',
      })
      const perPageWidth = 3
      const perPageHeight = 2
      const perPage = perPageWidth * perPageHeight
      const minMargin = 10
      const width = pdf.internal.pageSize.getWidth();    
      const height = pdf.internal.pageSize.getHeight();
      var imageWidth = 0
      var imageHeight = 0
      var widthMargin = minMargin
      var heightMargin = minMargin
      if (width < height) {
        imageWidth = (width - widthMargin * (perPageWidth + 1)) / perPageWidth
        imageHeight = imageWidth
        heightMargin = (height - (imageHeight * perPageHeight)) / (perPageHeight + 1)
      } else if (width > height) {
        imageHeight = (height - heightMargin * (perPageHeight + 1)) / perPageHeight
        imageWidth = imageHeight
        widthMargin = (width - (imageWidth * perPageWidth)) / (perPageWidth + 1)
      }
      var img = []
      canvasList.forEach((item) => {
          img.push(item.toDataURL("image/png", 1.0))
      })
      var pageHeight = 0
      var pageWidth = 0
      img.forEach((image, index) => {
        if (pageWidth % perPageWidth == 0 && index > 0) {
          pageWidth = 0
          pageHeight++
        }
        if (pageHeight % perPageHeight == 0 && index > 0) {
          pageHeight = 0
        }
        pdf.addImage(
          image, 
          'JPEG', 
          widthMargin + widthMargin * pageWidth + imageWidth * pageWidth, 
          heightMargin + heightMargin * pageHeight + imageHeight * pageHeight, 
          imageWidth, 
          imageHeight
        );
        if (((pageHeight+1) * (pageWidth+1)) % perPage == 0 && (index+1) != perPage) {
          pdf.addPage()
        }
        pageWidth++
      })
      const fileName = 'Flow_graph-' + this.project.name + '-' + Date.now() + ".pdf"
      pdf.save(fileName)
    },
  }
}
</script>

<style>

</style>