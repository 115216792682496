<template>
  <div id="sonic.water.output.visual.index">
    <b-row>
      <b-col md='12' class="text-center">
        <h1 class="display-4">{{$t('sonic.project.visual.title')}}</h1>
        <hr>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center">
      <b-col lg="4">
        <pressure-chart />
      </b-col>
      <b-col lg="4">
        <temperature-chart />
      </b-col>
      <b-col lg="4">
        <mach-chart />
      </b-col>
      <b-col lg="4">
        <velocity-chart />
      </b-col>
      <b-col lg="4">
        <vapor-quality-chart />
      </b-col>
      <b-col lg="4">
        <mdot-chart />
      </b-col>
    </b-row>
    <save-button />
  </div>
</template>

<script>
import pressureChart from './charts/pressure'
import temperatureChart from './charts/temperature'
import machChart from './charts/mach'
import velocityChart from './charts/velocity'
import vaporQualityChart from './charts/vaporQuality'
import mdotChart from './charts/mdot'
import saveButton from './save'
import { mapGetters } from 'vuex'

export default {
  name: 'sonic.water.output.visual.index',
  components: {
    pressureChart,
    temperatureChart,
    machChart,
    velocityChart,
    vaporQualityChart,
    mdotChart,
    saveButton
  },
  computed: {
    ...mapGetters({
      results: 'getSonicResults'
    }),
    onRow() {
      if (this.results.data.info.serial.plot < 10) {
        return '4'
      } else if (this.results.data.info.serial.plot < 20) {
        return '6'
      } else {
        return '12'
      }
    }
  }
}
</script>

<style>

</style>