<template>
  <div id="sonic.water.output.numerical.flow.table">
    <b-row>
      <b-col md="12">
        <div class="table-responsive">
          <table class="table table-striped table-hover table-bordered">
            <thead>
              <tr>
                <th colspan="14" class="text-center">{{$t('sonic.project.numerical.flow.title')}}</th>
              </tr>
              <tr>
                <th class="text-center">{{$t('sonic.project.numerical.flow.table.order')}}</th>
                <th>{{$t('sonic.project.numerical.flow.table.type')}}</th>
                <th
                  colspan="2"
                  class="text-center"
                >{{$t('sonic.project.numerical.flow.table.pressure')}}</th>
                <th
                  colspan="2"
                  class="text-center"
                >{{$t('sonic.project.numerical.flow.table.temperature')}}</th>
                <th colspan="2" class="text-center">{{$t('sonic.project.numerical.flow.table.dp')}}</th>
                <th
                  colspan="2"
                  class="text-center"
                >{{$t('sonic.project.numerical.flow.table.density')}}</th>
                <th
                  colspan="2"
                  class="text-center"
                >{{$t('sonic.project.numerical.flow.table.velocity')}}</th>
                <th
                  colspan="2"
                  class="text-center"
                >{{$t('sonic.project.numerical.flow.table.vaporQuality')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in results.serial.table" :key="index">
                <td class="text-center">
                  <b>{{ index + 1 }}</b>
                </td>
                <td>
                  <div
                    v-if="index === 0"
                  ><b>{{$t('sonic.project.numerical.flow.inlet')}}</b></div>
                  <div v-else-if="index < results.serial.table.length">{{results.serial.table[index]}}</div>
                  <div v-else><b>{{$t('sonic.project.numerical.flow.outlet')}}</b></div>
                </td>
                <td class="text-right">
                  {{ results.P.table[index].value }}
                </td>
                <td>{{$tc('units.pressure.short',0)}}</td>
                <td class="text-right">
                  {{ results.T.table[index].value }}
                </td>
                <td>{{$tc('units.temperature.short',0)}}</td>
                <td class="text-right">
                  <div v-if="index===0">---</div>
                  <div v-else>{{ results.dP.table[index-1].value }}</div>
                </td>
                <td>{{$tc('units.pressure.short',0)}}</td>
                <td class="text-right">
                  {{ results.rho.table[index].value }}
                </td>
                <td>{{$tc('units.density.short',0)}}</td>
                <td class="text-right">
                  {{ results.vel.table[index].value }}
                </td>
                <td>{{$tc('units.velocity.short',0)}}</td>
                <td class="text-right">
                  {{ results.x.table[index].value }}
                </td>
                <td>{{$tc('units.vaporQuality.short',0)}}</td>
              </tr>
            </tbody>
          </table>
          <br />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "sonic.water.output.numerical.flow.table",
  computed: {
    ...mapGetters({
      results: "getSonicResults",
    }),
  },
  methods: {
    roundValue(value) {
      return Number((value).toFixed(2));
    },
  }
};
</script>

<style>
</style>