<template>
  <div id="sonic.water.output.visual.charts.pressure">
    <line-chart :id="chartId" :chart-data="chartData" :options="chartData.options" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LineChart from "@/scripts/vendor/LineChart";
import logic from "@/scripts";
export default {
  name: "sonic.water.output.visual.charts.pressure",
  components: { LineChart },
  computed: {
    ...mapGetters({
      results: "getSonicResults",
    }),
    chartId() {
      return logic.output.visual.pressure.id;
    },
    chartData() {
      return {
        labels: logic.output.visual.parseParts(this.results.serial.graph),
        datasets: [
          {
            label:
              this.$t("units.pressure.title") +
              " [" +
              this.$tc("units.pressure.short", 0) +
              "]",
            chartId: logic.output.visual.pressure.id,
            backgroundColor: logic.output.visual.pressure.backgroundColor,
            borderColor: logic.output.visual.pressure.borderColor,
            lineTension: logic.output.visual.pressure.lineTension,
            fill: logic.output.visual.pressure.lineFill,
            data: this.results.P.graph.data
          },
        ],
        options: {
          responsive: logic.output.visual.pressure.responsive,
        },
      };
    },
  },
};
</script>

<style>
</style>