<template>
  <div id="sonic.water.output.numerical.calculated.table">
    <b-row>
      <b-col md="12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col" colspan="3" class="text-center">Calculated variables</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-right">{{$t('sonic.project.numerical.calculated.table.mdot.title')}}</td>
              <td class="text-right">
                <b>{{results.outlet.mdot[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.mdot.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td
                class="text-right"
              >{{$t('sonic.project.numerical.calculated.table.pressure.title')}}</td>
              <td class="text-right">
                <b>{{results.outlet.P[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.pressure.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td
                class="text-right"
              >{{$t('sonic.project.numerical.calculated.table.temperature.title')}}</td>
              <td class="text-right">
                <b>{{results.outlet.T[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.temperature.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td
                class="text-right"
              >{{$t('sonic.project.numerical.calculated.table.density.title')}}</td>
              <td class="text-right">
                <b>{{results.outlet.rho[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.density.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td
                class="text-right"
              >{{$t('sonic.project.numerical.calculated.table.velocity.title')}}</td>
              <td class="text-right">
                <b>{{results.outlet.vel[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.velocity.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td class="text-right">{{$t('sonic.project.numerical.calculated.table.mach.title')}}</td>
              <td class="text-right">
                <b>{{results.outlet.mach[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.mach.short',0)}}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "sonic.water.output.numerical.calculated.table",
  computed: {
    ...mapGetters({
      results: 'getSonicResults'
    })
  }
};
</script>

<style>
</style>