<template>
  <div id="sonic.water.output.visual.charts.temperature">
    <line-chart :id="chartId" :chart-data="chartData" :options="chartData.options" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LineChart from '@/scripts/vendor/LineChart';
import logic from '@/scripts'
export default {
  name: 'sonic.water.output.visual.charts.temperature',
  components: {LineChart},
  computed: {
    ...mapGetters({
      results: 'getSonicResults'
    }),
    chartId() {
      return logic.output.visual.temperature.id
    },
    chartData () {
      return {
        labels: logic.output.visual.parseParts(this.results.serial.graph),
        datasets: [
          {
            label: this.$t('units.temperature.title') + ' [' + this.$tc('units.temperature.short',0) + ']',
            chartId: logic.output.visual.temperature.id,
            backgroundColor: logic.output.visual.temperature.backgroundColor,
            borderColor: logic.output.visual.temperature.borderColor,
            lineTension: logic.output.visual.temperature.lineTension,
            fill: logic.output.visual.temperature.lineFill,
            data: this.results.T.graph.data
          }
        ],
        options: {
          responsive: logic.output.visual.temperature.responsive,
        }
      }
    },
  },
}
</script>

<style>

</style>