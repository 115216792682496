<template>
  <div>
    <b-row id="sonic.water.output.numerical.save">
      <b-col md="12" class="text-center">
        <download-button
        :text="$t('sonic.project.numerical.buttons.save')"
        v-on:clicked="downloadExcel()"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import XLSX from 'xlsx';
import downloadButton from "@/components/general/downloadButton.vue";
export default {
  name: 'sonic.water.output.numerical.save',
  components: {
    downloadButton
  },
  computed: {
    ...mapGetters({
      project: 'getSonicProject',
      results: 'getSonicResults'
    })
  },
  methods: {
    roundValue(value) {
      return Number((value).toFixed(2));
    },
    downloadExcel(){
      // export json to Worksheet of Excel
      // only array possible
      var table = []
      this.results.serial.table.forEach((serial, index) => {
        table.push({
          'Parts': serial, 
          'Pressure':this.results.P.table[index].value,
          'dP':index === 0 ? 0 : this.results.dP.table[index - 1].value,
          'Temperature':this.results.T.table[index].value,
          'Density':this.results.rho.table[index].value,
          'Vapor quality':this.results.x.table[index].value,
          'Velocity':this.results.vel.table[index].value,
          'Mach number':this.results.mach.table[index].value,
        })
      });
      var data = XLSX.utils.json_to_sheet(table)

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, data, 'flow')

      // export Excel file
      const fileName = 'Flow_table-' + this.project.name + '-' + Date.now() + ".xlsx"
      XLSX.writeFile(wb, fileName)
      
    }
  }
};
</script>

<style>
</style>