<template>
  <div id="sonic.output.water.index">
    <b-card header-tag="nav">
      <template v-slot:header>
        <b-navbar toggleable="lg" class="text-center">
          <b-navbar-brand href="#">Output</b-navbar-brand>

          <b-navbar-toggle target="sonic-output-nav"></b-navbar-toggle>

          <b-collapse id="sonic-output-nav" is-nav>
            <b-navbar-nav>
              <b-nav-item
                :active="isTabActive('numerical')"
                @click.prevent="setActive('numerical')"
              >{{$t('sonic.project.numerical.navTitle')}}</b-nav-item>
              <b-nav-item
                :active="isTabActive('visual')"
                @click.prevent="setActive('visual')"
              >{{$t('sonic.project.visual.navTitle')}}</b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-button
                v-if="!isProjectInProjects"
                variant="success"
                class="m-1"
                @click="linkProject()"
              >
                <i class="fas fa-save"></i>
                {{$t('sonic.project.buttons.save')}}
              </b-button>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </template>
      <div class="tab-content py-2" id="myTabContent">
        <div
          class="tab-pane fade"
          :class="{ 'active show': isTabActive('numerical') }"
          id="numerical"
        >
          <Numerical />
        </div>
        <div class="tab-pane fade" :class="{ 'active show': isTabActive('visual') }" id="visual">
          <Visual />
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Numerical from "./numerical";
import Visual from "./visual";
export default {
  name: "sonic.output.water.index",
  components: {
    Numerical,
    Visual,
  },
  computed: mapGetters({
    page: "getActivePageSonicOutput",
    isProjectInProjects: "isSonicProjectInProjects",
  }),
  methods: {
    isTabActive(menuTab) {
      return this.page.active === menuTab;
    },
    setActive(menuTab) {
      const data = {
        page: "output",
        tab: menuTab,
      };
      this.$store.dispatch("setSonicPageActiveTab", data);
    },
    linkProject() {
      this.$store.dispatch("linkSonicProject");
    },
  },
};
</script>

<style>
</style>